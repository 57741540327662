
import { defineComponent } from 'vue';
import store from "@/store";
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import InputSwitch from 'primevue/inputswitch';
import CustomerService from "@/services/CustomerService";

import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

const customerService = new CustomerService(process.env.VUE_APP_ABSTRACTION_API);

export default defineComponent({
  name: 'AddShippingAddressDialog',

  components: {
    Dialog,
    InputText,
    Dropdown,
    Button,
    InputSwitch,
  },

  props: {
    header: {
      type: String,
      default: 'Address',
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    addAddressToCustomerSwitch: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: String,
      default: '',
    }
  },
  created() {
    this.show = this.showDialog;
  },

  updated() {
    this.show = this.showDialog;
    this.addAddressToCustomer = true;

    customerService.getCustomer(
      this.customerId,
      store.getters["session/getClient"],
      "saleopp_ids soquote_ids so_ids ticket_ids ar_ids rma_ids log_ids highest_ar_bal avg_ar_bal",
    ).then((response: any) => {
      let original = response.carrier_items;
      let reformatted: Array<any> = [];
      original?.forEach((carrier: any) => {
        carrier.frt_account_items?.forEach((part: any) => {
          let partObject = {
            frt_account: part.frt_account,
            carrier: carrier.carrier,
          };
          reformatted.push(partObject);
        });
      });
      this.thirdPartyList = JSON.parse(JSON.stringify(reformatted));
    })
  },

  computed: {
    countriesData() {
      const countries = store.getters["control/getCountries"];
      return countries;
    },
    getTaxCodes() {
      const taxCodes = store.getters["control/getStax"];
      return taxCodes;
    },
  },

  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations(){
    return {
      newShippingAddress: {
        ship_name: { required: helpers.withMessage("Name is required", required) },
        ship_city: { required: helpers.withMessage("City is required", required) },
        ship_state: { required: helpers.withMessage("State is required", required) },
        ship_zip: { required: helpers.withMessage("Zip is required", required) },
        ship_country: { required: helpers.withMessage("Country is required", required) },
      }
    }
  },

  data(){
    return {
      thirdPartyList: Array<any>(),
      addAddressToCustomer: true,
      isSubmitting: false,
      countryCode: "",
      isSubmitted: false,
      show: false,
      newShippingAddress: {
        ship_seq: "",
        ship_city: "",
        ship_state: "",
        ship_zip: "",
        ship_name: "",
        ship_country: "",
        ship_attn: "",
        ship_comm: "",
        ship_to_items: [
          {  ship_to: ""  }
        ],
        ship_email: "",
        ship_phone: "",
        ship_fax: "",
        ship_to_tax_items: [],
        ship_acct_no_items: []
      }
    }
  },

  emits: ["closeDialog", "saveAddress"],

  methods: {
    getCountries() {
      const countryOptions = this.countriesData?.map(
        (element: any) => element.country
      );
      return countryOptions;
    },
    getCountryCode(event: any) {
      for (let i = 0; i < this.countriesData.length; i++) {
        if (event === this.countriesData[i].country) {
          this.countryCode = this.countriesData[i].country_code;
          return this.countryCode;
        }
      }
    },
    emailValidation(email: any) {
      if (email == null) {
        return true;
      } else {
        return /^\S+@\S+\.\S+$/.test(email);
      }
    },
    closeAddressDialog () {
      this.show = false
      this.isSubmitted = false
      this.clearNewShippingAddress()
      this.$emit("closeDialog")
    },
    async saveAddressInfo () {
      this.isSubmitted = true

      const isNewShippingAddressValid = await this.v$.newShippingAddress.$validate()
      const areValidStreets = this.newShippingAddress.ship_to_items.every((item: any) => item.ship_to.length > 0)

      if (!isNewShippingAddressValid || !areValidStreets || ( this.newShippingAddress.ship_email && !this.emailValidation(this.newShippingAddress.ship_email))) {
        return
      }

      this.$emit("saveAddress", this.newShippingAddress, this.addAddressToCustomer)
      this.clearNewShippingAddress()
      this.closeAddressDialog()
    },
    clearNewShippingAddress () {
      this.newShippingAddress = {
        ship_seq: "",
        ship_city: "",
        ship_state: "",
        ship_zip: "",
        ship_name: "",
        ship_country: "",
        ship_attn: "",
        ship_comm: "",
        ship_to_items: [
          {  ship_to: ""  }
        ],
        ship_email: "",
        ship_phone: "",
        ship_fax: "",
        ship_to_tax_items: [],
        ship_acct_no_items: []
      }
    },
    addAddressLine(index: number) {
      this.newShippingAddress.ship_to_items.splice(index + 1, 0, { ship_to: "" });
    },
    deleteAddressLine(index: number) {
      this.newShippingAddress.ship_to_items.splice(index, 1);
      if (this.newShippingAddress.ship_to_items.length === 0){
        this.newShippingAddress.ship_to_items.push({ ship_to: "" });
      }
    },
    isValidStreet(index: number) {
      return this.newShippingAddress.ship_to_items[index].ship_to.length > 0;
    },
  },
})

