
import { defineComponent, toRefs } from "vue";
import { mapActions, mapGetters } from "vuex"
import store from "@/store";
import Card from "primevue/card";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import AddShippingAddressDialog from "@/components/Orders/AddShippingAddressDialog.vue";
import Carousel from 'primevue/carousel';
import CustomerService from "@/services/CustomerService";
import CollapsibleSection from "@/components/UI/CollapsibleSection.vue";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf} from "@vuelidate/validators";

const customerService = new CustomerService(process.env.VUE_APP_ABSTRACTION_API);
import StaxService from "@/services/StaxService";
import Utils from "@/utils/utils";
import InputText from "primevue/inputtext";
import TextArea from "primevue/textarea";
const staxService = new StaxService(process.env.VUE_APP_ABSTRACTION_API)

export default defineComponent({
  name: 'ShippingInfo',
  components: {
    Card,
    AddShippingAddressDialog,
    Button,
    ProgressSpinner,
    Carousel,
    CollapsibleSection,
    InputText,
    TextArea
  },
  computed: {
    ...mapGetters({
      // getCustomer: "pos/getCustomer",
      getCustomer: "customer/getCust",
      shippingAddress: "pos/getShippingAddress",
      getOrder: "pos/getCurrentOrder",
      getSelectedCoCode: "control/getSelectedCoCode",
      getCompanyCodes: "control/getCompanyCodes",
      getCountries: "control/getCountries",
    }),
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  props: {
    orderSubmitted: Boolean
  },  
  validations() {
    return {
      PONumber: {
        required: requiredIf(() => {return this.getCustomer.po_required === 'Y'})
        }
    }
  },
  created() {
    if(this.shippingAddress) {
      this.getTaxes();
    }
    this.isLoading = true
    customerService.getCustomer(this.getCustomer.cust_id, store.getters["session/getClient"]).then((response: any) => {
      this.mailingAddress.ship_city = response.sort_city || ""
      this.mailingAddress.ship_state = response.sort_state || ""
      this.mailingAddress.ship_zip = response.sort_zip || ""
      this.mailingAddress.ship_country = response.sort_country || ""
      this.mailingAddress.ship_to_items = response.address_items.map((item: any) => ({ship_to: item.address}))
      this.mailingAddress.ship_phone = response.phone || ""

      this.isLoading = false
    }).catch((error) => {
      const notification = {
        message: error.response.data.error || error.toString(),
        type: "error",
      };
      store.dispatch("notification/add", notification);
      this.isLoading = false
    })

    if (!this.getCustomer.ship_seq_items) {
      this.setShipAdr(this.mailingAddress)
    }
    

    if (this.getCompanyCodes === null || (this.getCompanyCodes && this.getCompanyCodes.length === 0)){
      this.fetchControls({
        id: "COMPANY",
        procedure: "CO.CONTROL",
        filename: "CONTROL",
      }).then(() => {
        this.setCoCode();
      })
    } else {
      this.setCoCode();
    }

    if (this.getCountries == null || (this.getCountries && this.getCountries.length === 0)){
      this.fetchControls({
        id: "SHIP",
        procedure: "SHIP.CONTROL",
        filename: "CONTROL",
      })
    }
  },
  data () {
    return {
      carouselResponsiveOptions: [
        {
          breakpoint: '1199px',
          numVisible: 3,
          numScroll: 2
        },
        {
          breakpoint: '991px',
          numVisible: 3,
          numScroll: 2
        },
        {
          breakpoint: '767px',
          numVisible: 1,
          numScroll: 1
        }
      ],
      sectionsStatus: {
        addressesInfo: true,
        extraInformation: true
      },
      PONumber: "",
      isLoading: false,
      thirdPartyList: Array<any>(),
      showAddAddressDialog: false,
      localShippingAddress: [] as any[],
      mailingAddress: {
        ship_seq: "mailing",
        ship_city: "",
        ship_state: "",
        ship_zip: "",
        ship_name: "Customer Mailing Address",
        ship_country: "",
        ship_attn: "",
        ship_to_items: [] as any[],
        ship_email: "",
        ship_to_tax_items: [],
        ship_acct_no_items: []
      } as any,
      coCodesItems: Array<any>(),
      notes: ''
    }
  },
  methods: {
    ...mapActions({
      setLoadingTax: "pos/setLoadingTax",
      setShippingAddress: "pos/setShippingAddress",
      setTaxAmount: "pos/setTaxAmount",
      addCustomerShipSeqItem: "pos/addCustomerShipSeqItem",
      getCustomer: "customer/getCustomer",
      setSelectedCoCode: "control/setSelectedCoCode",
      fetchControls: "control/fetchControls",
      setPoNum: "pos/setPoNumber",
      setNotes: "pos/setNotes"
    }),
    setPO() {
      this.setPoNum(this.PONumber)
    },
    setNote() {
      this.setNotes(this.notes)
    },
    getSectionIconClass(status: {[key:string]: boolean}, section: "addressesInfo") {
      return status[section]
        ? "pi pi-chevron-down ml-2"
        : "pi pi-chevron-right ml-2";
    },
    toggleSectionIcon(tab:  "addressesInfo") {
      this.sectionsStatus[tab] = !this.sectionsStatus[tab];
    },
    isAddressSelected (data: any) {
      if (this.shippingAddress !== null) {
        if(data.ship_seq === this.shippingAddress.ship_seq){
          return true
        } else {
          return false
        }
      }
      return false
    },
    getCardTitleClass (data: any) {
      if (this.isAddressSelected(data)) {
        return "m-1 selected-address-card shadow-4"
      }
      return "m-1 address-card shadow-4"
    },
    setShipAdr (address: any) {
      this.setShippingAddress(address)
      this.getTaxes()
    },
    getTaxes() {
      this.setLoadingTax(true)
      let line_items = [] as any
      this.getOrder.forEach((item: any) => {
        line_items.push({
          lis: (line_items.length + 1).toString(),
          li_parts: item.part_number,
          li_order_qtys: (item.quantity).toString(),
          li_prices: item.price,
          li_taxables: item.taxable
        })
      });
      let ship_address_items = [] as any
      if(this.shippingAddress.ship_to_items) {
        this.shippingAddress.ship_to_items.forEach((addressLine:any) => {
          ship_address_items.push ({
            ship_address : addressLine.ship_to
          })
        });
      }
      staxService.calcStax({
        so_id: '',
        date: Utils.formatDate(Date()),
        sold_to: this.getCustomer.cust_id,
        book_date: Utils.formatDate(Date()),
        status: "N",
        cust_code: this.getCustomer.code,
        co_code: this.getSelectedCoCode,
        ship_address_items: ship_address_items,
        ship_attn: this.shippingAddress.ship_attn,
        ship_city: this.shippingAddress.ship_city,
        ship_comm: this.shippingAddress.ship_comm,
        ship_country: this.shippingAddress.ship_country,
        ship_name: this.shippingAddress.ship_name,
        ship_seq: this.shippingAddress.ship_seq,
        ship_state: this.shippingAddress.ship_state,
        ship_via: this.shippingAddress.ship_via,
        ship_zip: this.shippingAddress.ship_zip,
        lis_items: line_items,
      }).then((response: any)=> {
        if (response.status === 'success') {
          this.setTaxAmount(response.tax_amount)
        } else {
           const notification = {
              message: `Unable to calculate taxes. ${response.error}.`,
              type: "error",
            };
            store.dispatch("notification/add", notification);
        }
      }).catch((error) => {
        const notification = {
          message: error.response.data.error || error.toString(),
          type: "error",
        };
        store.dispatch("notification/add", notification);
      }).finally(()=> {
        this.setLoadingTax(false)
      })
    },
    formattedAddress (data: any) {
      let address = ''
         if (data.ship_attn) {
          address +=  data.ship_attn + '\n'
        }
        if (data.ship_to_items && data.ship_to_items.length > 0) {
          data.ship_to_items.forEach((addressLine: any) => {
            address += addressLine.ship_to + '\n'
          });
        }
        if (data.ship_city) {
          address += data.ship_city + ',  '
        }
        if (data.ship_state) {
          address += data.ship_state + '\n'
        }
        if (data.ship_zip) {
          address += data.ship_zip + ',  '
        }
        if (data.ship_country) {
          address += data.ship_country + '.'
        }
      return address
    },
    openAddAddressDialog () {
      this.showAddAddressDialog = true
    },
    closeAddAddressDialog () {
      this.showAddAddressDialog = false
    },
    async saveNewAddress (data: any, addNewAddress=false) {

      if (addNewAddress) {
        let [oldCust, newCust] = [{}, {}];
        let oldShipSeq = [...this.getCustomer.ship_seq_items];
        const nextSeqItemId = 
          (this.getCustomer.ship_seq_items.map((element: any) => element.ship_seq)
          .reduce((a: any, b:any ) => Math.max(a, b), -Infinity) + 1).toString()

        data.ship_seq = nextSeqItemId
        
        newCust = {
            cust_id: this.getCustomer.cust_id,
            ship_seq_items: [data, ...oldShipSeq],
          };
        oldCust = {
          cust_id: this.getCustomer.cust_id,
          ship_seq_items: [...oldShipSeq],
        };

        await store.dispatch("customer/updateCustomer", {
          id: this.getCustomer.cust_id,
          oldCust,
          newCust,
          Client: store.getters["session/getClient"],
        }).then((response) => {
          this.setShippingAddress(data)
          this.addCustomerShipSeqItem(data)
        }).catch((response) => {
          store.dispatch("notification/add", {
            message: `An error occurred while adding the address. ${response.error}.`,
            type: "error",
          });
        });

      } else {
        this.setShippingAddress({...data, ship_seq: 'new' + (this.localShippingAddress.length + 1).toString()})
        this.localShippingAddress.unshift({...data, ship_seq: 'new' + (this.localShippingAddress.length + 1).toString()})
      }
      
      this.getTaxes()
      this.closeAddAddressDialog()
    },
    addressesCards() {
      let addresses = this.getCustomer.ship_seq_items ? [this.mailingAddress, ...this.getCustomer.ship_seq_items] : [this.mailingAddress]
      if (this.localShippingAddress.length > 0) {
        addresses = [...this.localShippingAddress, ...addresses]
      }
      if (this.shippingAddress !== null && !addresses.some(address => {
        if(address.ship_seq === this.shippingAddress.ship_seq) {
          return true
        } else {
          return false
        }
      })) {
        addresses = [this.shippingAddress, ...addresses]
      }
      return addresses
    },
    setCoCode() {
      this.setSelectedCoCode(this.getCompanyCodes ? this.getCompanyCodes[0].code : '')

      if (this.getCompanyCodes && this.getCompanyCodes.length > 1){
        this.coCodesItems = this.getCompanyCodes.map((coCode: any) => {
          return {
            label: `${coCode.name}`,
            command: () => {
              this.setSelectedCoCode(coCode.code)
            },
          }
        })
      }
    },

  },
})
